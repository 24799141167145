

import { gsap } from 'gsap/all';
import SlideshowImage from './SlideshowImage.js';


class Slideshow {

	
	
	/*-------------------------------------------
	 *	METHODS
	 *-----------------------------------------*/
	
	/**
	*	Slideshow
	*	@param null
	*	@returns null	
	*/
	constructor (_settings) {
		
		this.container = _settings.container;
		this.handlesResize = _settings.handlesResize;

		this.speed = this.container.getAttribute('data-speed');
		if (this.speed === null) this.speed = 4;
		
		if (_settings.uiColour !== undefined) {
			this.uiColour = _settings.uiColour;
		} else {
			this.uiColour = '#FFFFFF';
		}

		this.tweenOb = {};
		this.currSlideIndex = 0; 
		this.animationActive = false;
		this.hasInteracted = false;
				
		this.initSlides();
		this.initNextPrev();
		this.initPagination();

		if (this.handlesResize) {
			window.addEventListener('resize', this.handleWindowResize.bind(this));
			this.handleWindowResize();
		}

		this.initImages();

		this.autoTween();

	}

	
	/**
	*	initSlides
	*	@param null
	*	@returns null	
	*/ 
	initSlides () { 
		this.slidesContainer = this.container.querySelector('.js-slidesContainer');
		this.slides = this.slidesContainer.querySelectorAll('.js-slide');
		this.totalSlides = this.slides.length;
		this.currSlide = this.slides[this.currSlideIndex];
		this.currSlide.style.opacity = 1;
		this.slidesContainer.appendChild(this.currSlide);
		this.heightRatio = this.container.getAttribute('data-height-ratio');
	}
	
	
	/**
	*	initImages
	*	@param null
	*	@returns null	
	*/ 
	initImages () { 
		Array.prototype.forEach.call(this.container.querySelectorAll('.js-slideImg'), (element, index) => {
			new SlideshowImage( {
				img: element,
				imgContainer: this.container,
				handlesResize: this.handlesResize
			} );
		} );
	}
	

	/**
	*	initNextPrev
	*	@param null
	*	@returns null	
	*/ 
	initNextPrev () { 

		if (this.totalSlides === 1) return;

		this.btnNext = document.createElement('a');
		this.btnNext.classList.add('slideshow--next-btn');
		this.btnNext.setAttribute('href', '#to-next-slide');
		this.btnNext.setAttribute('title', 'to next slide');
		this.btnNext.addEventListener('click', (event) => {
			event.preventDefault();
			if (!this.animationActive) {
				this.clearAutoTween();
				this.hasInteracted = true;
				this.nextSlide();
			}
		});
		this.container.appendChild(this.btnNext);

		this.btnPrev = document.createElement('a');
		this.btnPrev.classList.add('slideshow--prev-btn');
		this.btnPrev.setAttribute('href', '#to-previous-slide');
		this.btnPrev.setAttribute('title', 'to previous slide');
		this.btnPrev.addEventListener('click', (event) => {
			event.preventDefault();
			if (!this.animationActive) {
				this.clearAutoTween();
				this.hasInteracted = true;
				this.previousSlide();
			}
		});
		this.container.appendChild(this.btnPrev);

	}
	

	/**
	*	initPagination
	*	@param null
	*	@returns null	
	*/ 
	initPagination () { 
		
		if (this.totalSlides === 1) return;

		this.pagination = document.createElement('div');
		this.pagination.classList.add('slideshow--pagination');
		const ul = document.createElement('ul');
		this.pagination.appendChild(ul);
		this.container.appendChild(this.pagination);

		let li;
		let a;
		this.paginationLinks = [];
		for (let i = 0; i < this.totalSlides; i++) {
			li = document.createElement('li');
			a = document.createElement('a');
			a.style.border = '1px solid ' + this.uiColour;
			a.href = '#to-media-' + (i + 1);
			li.appendChild(a);
			ul.appendChild(li);
			this.paginationLinks.push(a);
		}

		Array.prototype.forEach.call(this.paginationLinks, (element, index) => {
			element.addEventListener('click', (event) => {
				event.preventDefault();
				if (!this.animationActive) {
					this.clearAutoTween();
					this.currSlideIndex = index;
					this.hasInteracted = true;
					this.animateSlide();
				}
			});
		} );
		this.currPaginationLink = this.paginationLinks[this.currSlideIndex];
		this.currPaginationLink.classList.add('active');
		this.currPaginationLink.style.border = '5px solid ' + this.uiColour;

	}


	/**
	*	autoTween
	*	@param null
	*	@returns null	
	*/
	autoTween () {
		this.tweenObj = { turn: 0 };
		gsap.to(this.tweenObj, 0.1, { delay: this.speed, turn: 1, onComplete: () => {
			this.nextSlide();
		} } );
	}
	
	
	/**
	*	clearAutoTween
	*	@param null
	*	@returns null	
	*/
	clearAutoTween () { 
		gsap.killTweensOf(this.tweenObj);
	}
	
	
	/**
	*	nextSlide
	*	@param null
	*	@returns null	
	*/
	nextSlide () {
		if (this.currSlideIndex < (this.totalSlides-1)) { //next, otherwise back to start
			this.currSlideIndex++;
		} else {
			this.currSlideIndex = 0;
		}
		this.animateSlide();
	}
	
	
	/**
	*	previousSlide
	*	@param null
	*	@returns null	
	*/
	previousSlide () {
		if (this.currSlideIndex === 0) { //next, otherwise back to start
			this.currSlideIndex = this.totalSlides-1;
		} else {
			this.currSlideIndex--;
		}
		this.animateSlide();
	}
	
	
	/**
	*	animateSlide
	*	@param null
	*	@returns null	
	*/
	animateSlide () {
		
		this.animationActive = true;
		
		this.currSlide = this.slides[this.currSlideIndex];
		this.slidesContainer.appendChild(this.currSlide);
		this.currSlide.style.opacity = 0;
		gsap.to(this.currSlide, 0.6, { opacity: 1, ease: 'Linear.easeNone', onComplete: () => {
			this.animationActive = false;
			if (!this.hasInteracted) {
				this.autoTween();
			}
		} } );
		
		this.updatePagination();
		
	}
	
	
	/**
	*	updatePagination
	*	@param null
	*	@returns null	
	*/
	updatePagination () {
		this.currPaginationLink.classList.remove('active');
		this.currPaginationLink.style.border = '1px solid ' + this.uiColour;
		this.currPaginationLink = this.paginationLinks[this.currSlideIndex];
		this.currPaginationLink.classList.add('active');
		this.currPaginationLink.style.border = '5px solid ' + this.uiColour;
	}
	
	
	/**
	*	handleWindowResize
	*	@param null
	*	@returns null	
	*/
	handleWindowResize () {
		
		let windowWidth; 
		let windowHeight;

		if (this.container.getAttribute('data-is-full-screen')) {
			windowWidth = window.innerWidth; 
			windowHeight = window.innerHeight;
		} else {
			windowWidth = window.innerWidth; 
			windowHeight = windowWidth * this.heightRatio;
		}

		this.container.style.height = windowHeight;
		
	}
	
	
	/**
	*	dispose
	*	@param null
	*	@returns null	
	*/
	dispose () {
		window.removeEventListener('resize', this.handleWindowResize.bind(this));
		this.clearAutoTween();
	}
	
	
	
}
	
	
export default Slideshow;