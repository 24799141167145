import React from 'react';
import SEO from '../components/seo/SEO';
import ViewSlideshow from '../components/slideshow/ViewSlideshow';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';


export default function Index () { 

	const data = useStaticQuery(graphql`
	{
		allFile(filter: {relativeDirectory: {eq: "home"}}, sort: { fields: [base] }) {
			edges {
				node {
					id
					base
					childImageSharp {
						fixed(width: 1920, height: 1080, quality: 80) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		}
	}
	`)

	const images = data.allFile.edges.map( ( { node } ) => 
		<Img fixed={ node.childImageSharp.fixed } key={ node.id } alt='home image' />
	);
	  
	return (
		<>
			<SEO title='Home' />
			<ViewSlideshow images={ images } />
		</>
	);

}
